import React, { useEffect, useState } from "react";
import "./style.scss";
import CheckIcon from "../../assets/Images/India/location/check.svg";

import BangaloreMap from "../../assets/Images/India/location/bangalore.svg";
import BangalorePin from "../../assets/Images/India/location/pin/bangalore.svg";
import HyderabadMap from "../../assets/Images/India/location/hyderabad.svg";
import HyderabadPin from "../../assets/Images/India/location/pin/hyderabad.svg";
import PuneMap from "../../assets/Images/India/location/pune.svg";
import PunePin from "../../assets/Images/India/location/pin/pune.svg";
import ChennaiMap from "../../assets/Images/India/location/chennai.svg";
import ChennaiPin from "../../assets/Images/India/location/pin/chennai.svg";
import RevealAnimation from "../RevealAnimation";

function LocationTab() {
  const [selectedTab, setSelectedTab] = useState("Bangalore");
  const [activeTabData, setActiveData] = useState({});

  const data = {
    tabList: [
      { title: "Bangalore", iconUrl: BangalorePin },
      { title: "Hyderabad", iconUrl: HyderabadPin },
      { title: "Pune", iconUrl: PunePin },
      { title: "Chennai", iconUrl: ChennaiPin },
    ],
    tabContent: [
      {
        title: "Bangalore",
        imgUrl: BangaloreMap,
        content: [
          {
            title: "Flipkart:",
            description: (
              <>
                <b>Valuation:</b> Approximately $37.6 billion as of the latest
                funding round.
              </>
            ),
          },
          {
            title: "Ola Cabs:",
            description: (
              <>
                <div>
                  <b>Operations:</b> Operates across India and has expanded to
                  several international markets including Australia and the UK.
                </div>
                <div>
                  <b>Orders:</b> Processes over 1.5 million food orders daily
                  across India.
                </div>
              </>
            ),
          },
          {
            title: "Byju's:",
            description: (
              <>
                <b>Valuation:</b> Valued at around $21 billion, one of the
                world’s most valuable edtech companies.
              </>
            ),
          },
        ],
      },
      {
        title: "Hyderabad",
        imgUrl: HyderabadMap,
        content: [
          {
            title: "Bharat Biotech:",
            description: (
              <>
                <b>Achievement:</b> Developer of Covaxin, India’s indigenous
                COVID-19 vaccine, which is approved in several countries.
              </>
            ),
          },
          {
            title: "T-Hub:",
            description: (
              <>
                <b>Impact:</b> India’s largest incubator for startups, fostering
                a significant number of startup innovations and collaborations.
              </>
            ),
          },
          {
            title: "IHub-Data:",
            description: (
              <>
                <b>Focus:</b> A government-supported initiative focusing on data
                analytics and AI, supporting startups and research in these
                fields.
              </>
            ),
          },
        ],
      },
      {
        title: "Pune",
        imgUrl: PuneMap,
        content: [
          {
            title: "FirstCry:",
            description: (
              <>
                <b>Reach:</b> Asia’s largest online store for baby and
                children’s products, with a customer base of over 4 million.
              </>
            ),
          },
          {
            title: "Persistent Systems:",
            description: (
              <>
                <b>Market Cap:</b> Approximately $2 billion, specialising in
                software and technology services.
              </>
            ),
          },
          {
            title: "Faasos:",
            description: (
              <>
                <b>Daily Orders:</b> Receives around 20,000 daily orders across
                its service areas in India.
              </>
            ),
          },
        ],
      },
      {
        title: "Chennai",
        imgUrl: ChennaiMap,
        content: [
          {
            title: "BankBazaar:",
            description: (
              <>
                <b>Traffic:</b> Over 40 million visitors annually, specialising
                in online financial services like loans and credit cards.
              </>
            ),
          },
          {
            title: "Chargebee:",
            description: (
              <>
                <b>Valuation:</b> Recently valued at over $1.4 billion,
                providing subscription billing and revenue management platform.
              </>
            ),
          },
          {
            title: "Faasos:",
            description: (
              <>
                <b>Valuation:</b> Achieved a market cap of over $12 billion post
                its NASDAQ listing, offering customer engagement software
                globally.
              </>
            ),
          },
        ],
      },
    ],
  };

  useEffect(() => {
    const selectedContent = data?.tabContent?.find(
      (location) => location.title === selectedTab
    );
    setActiveData(selectedContent);
  }, [selectedTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedTab((prevTab) => {
        const currentIndex = data.tabList.findIndex(
          (tab) => tab.title === prevTab
        );
        const nextIndex = (currentIndex + 1) % data.tabList.length;
        return data.tabList[nextIndex].title;
      });
    }, 3000); // Change tab every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <>
      <div className="location-tabs-wrap">
        <div className="location-tabs-container body-container">
          <div className="location-tabs-maps">
            <RevealAnimation className="location-tabs-img">
              <img src={activeTabData?.imgUrl} alt="" />
            </RevealAnimation>

            <div className="location-tabs-list">
              {data.tabList.map((tab, tabIndex) => (
                <div
                  className={`location-tabs-item ${
                    tab.title === activeTabData.title ? "active" : ""
                  }`}
                  key={tabIndex}
                  onClick={() => setSelectedTab(tab.title)}
                >
                  <div className="location-tab-icon">
                    <img src={tab.iconUrl} alt="" />
                  </div>
                  <div className="location-tab-title">{tab.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="location-tabs-content">
            <RevealAnimation className="title-header title-underline">
              {activeTabData?.title}
            </RevealAnimation>
            <div className="location-tab-main-content-list">
              {activeTabData?.content?.map((content, i) => (
                <div key={i} className="location-tab-main-content-item">
                  <RevealAnimation className="item-check">
                    <img src={CheckIcon} alt="" />
                  </RevealAnimation>
                  <div className="item-content">
                    <RevealAnimation component={"p"} className="title">
                      {content.title}
                    </RevealAnimation>
                    <RevealAnimation className="description">
                      {content.description}
                    </RevealAnimation>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationTab;
